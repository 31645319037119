<template>
  <div :id="'vitrina-' + slug" class="carousel-products">
    <div>
      <h2 class="title-carousel">{{ title }} /</h2>
    </div>
    <carousel
      :key="innerWidth"
      class="col-12 mx-0 position-relative carousel-products-content"
      :dots="true"
      :lazy-load="true"
      :nav="false"
      :responsive="{
        0: { items: 1, nav: false, stagePadding: 30 },
        704: { items: 2, nav: false, stagePadding: 60 },
        1030: { items: itemsXfila, nav: false, stagePadding: 80 },
      }"
    >
      <template slot="prev"
        ><span class="prev nav-button d-none d-md-block">
          <img
            src="@/assets/icons/left-carousel.svg"
            alt="left arrow carousel"
          /> </span
      ></template>
      <template slot="next"
        ><span class="next nav-button d-none d-md-block">
          <img
            src="@/assets/icons/right-carousel.svg"
            alt="right arrow carousel"
          /> </span
      ></template>
      <div
        v-for="(product, index) in products"
        :key="index"
        class="text-decoration-none h-100"
        @click="clicProduct(productsAnalytics, product.producto_sku)"
      >
        <div class="h-100">
          <card-product-hover
            class="simple-carousel h-100"
            :product="product"
          />
        </div>
      </div>
      <router-link
        v-if="slug"
        :to="{ name: 'TiendaCategory', params: { category: slug } }"
      >
        <div class="last-card-carousel">
          <p>
            Descubre aquí más
            <!-- de <br> <strong>140</strong>-->
            <br />
            productos similares
          </p>
        </div>
      </router-link>
    </carousel>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import carousel from 'v-owl-carousel'
import CardProductHover from '@/components/Templates/CardProductHover.vue'
import { GA4ViewItemList } from '@/GA4/events'

export default {
  components: { carousel, CardProductHover },

  props: {
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    itemsXfila: {
      type: Number,
      required: false,
      default: 3,
    },
    slug: {
      type: String,
      required: false,
      default: '',
    },
    productsAnalytics: {
      type: Array,
      required: false,
      default: () => [],
    },
    position: {
      type: String,
      required: false,
      default: 'carousel',
    },
  },
  data() {
    return {
      showBannerVitrina: false,
    }
  },
  computed: {
    ...mapState(['innerWidth']),
  },
  watch: {
    innerWidth() {
      this.validateDotCarousel()
    },
  },
  mounted() {
    this.validateDotCarousel()
    window.addEventListener('scroll', () => {
      if (document.getElementById('vitrina-' + this.slug)) {
        let vitrina = document.getElementById('vitrina-' + this.slug)
        if (
          vitrina &&
          !this.showBannerVitrina &&
          vitrina.getBoundingClientRect().top -
            (window.innerHeight - vitrina.offsetHeight / 2) <=
            0
        ) {
          GA4ViewItemList(this.products, this.position, this.title)
          this.showBannerVitrina = true
          window.removeEventListener('scroll', () => {})
        }
      }
    })
  },
  methods: {
    clicProduct(dataAnalitycs, skuSearch) {
      let productAnalitycs = dataAnalitycs?.find(e => e.id == skuSearch)
      if (productAnalitycs) {
        // DK-ProductClick
        // window.dataLayer.push({
        //   event: 'DK-ProductClick',
        //   name: productAnalitycs.name,
        //   id: productAnalitycs.id,
        //   price: productAnalitycs.price,
        //   brand: productAnalitycs.brand,
        //   category: productAnalitycs.category,
        //   variant: productAnalitycs.variant,
        //   position: productAnalitycs.position,
        // })
      }
    },
    validateDotCarousel() {
      let element = document.getElementsByClassName('owl-dot')
      if (element) {
        for (let index = 0; index < element.length.length; index++) {
          element[index].ariaLabel = 'Dot carousel'
          element[index].style.width = 100 / this.itemsXfila - 1 + '%'
        }
      }
    },
  },
}
</script>
